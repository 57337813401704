class CalculationService {

    constructor(savings) {
        this.savingsList = savings;
    }

    calculateSpotPricesWithSavingsAndVAT(savings, weekCount, pricePrWeek, selectedPriceRangeIndex) {
        var priceObj = {
            priceWithoutVAT: 0,
            VAT: 0,
            priceWithVAT: 0,
            savings: 0
        };

        if(this)

        var combinedPrice = pricePrWeek * weekCount;
        
        if (savings.length === undefined) {
            priceObj.priceWithoutVAT = combinedPrice;
            priceObj.VAT = combinedPrice * 0.25;
            priceObj.priceWithVAT = combinedPrice + (combinedPrice * 0.25);
            priceObj.savings = 0;
        }
        else {
            var percentage = 0;
            for (var index = savings.length - 1; index >= 0; index--) {
                if (weekCount >= savings[index].attributes.Weeks) {
                    percentage = savings[index].attributes.Percentage;
                    index = -1;
                }
            }

            priceObj.savings = (combinedPrice * (percentage / 100)).toFixed(2);
            priceObj.priceWithoutVAT = combinedPrice - priceObj.savings;
            priceObj.VAT = priceObj.priceWithoutVAT * 0.25;
            priceObj.priceWithVAT = priceObj.priceWithVAT + priceObj.VAT;
            priceObj.selectedPriceIndex = selectedPriceRangeIndex;
        }

        return priceObj;
    }

}

export default new CalculationService();