<template>
  <div class="shopping-cart-page">
    <el-main>
      <div v-if="!bookingCompleted" class="shopping-cart-contianer">
        <div class="shopping-cart-title">Kurv ({{ cartItems.length }})</div>

        <!-- Shopping cart item -->
        <div
          class="shopping-cart-item"
          v-for="(item, index) in cartItems"
          :key="item.id"
        >
          <el-row>
            <el-col :xs="0" :sm="6" :md="6" :lg="6" :xl="6">
              <a :href="`#/reklameskaerm/${item.id}`">
              <img :src="item.image" class="item-image" />
              </a>
            </el-col>
            <el-col :xs="10" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="item-description">
                <div class="item-title">{{ item.title }}</div>
                <div class="item-region-and-city">
                  {{ item.region }}, {{ item.city }}
                </div>
                <div class="item-address">{{ item.address }}</div>
                <div class="item-weekcount">
                  Antal uger: {{ item.selectedWeeks.length }}
                </div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="4" :md="4" :lg="4" :xl="4">
              <div class="item-actions">
                <!-- <el-button
                  @click="openWeekEditorForItem(item)"
                  class="item-action-edit"
                  icon="el-icon-edit"
                  circle
                ></el-button> -->
                <el-button
                  @click="removeItemFromCart(index)"
                  class="item-action-remove"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </div>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <div class="item-price">
                Pris: <span>{{ ConvertToLocalString(item.price.priceWithoutVAT) }} kr</span>
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- Shooping cart total -->
        <div class="shopping-cart-checkout">
          <el-row>
            <el-col :xs="0" :sm="14" :md="16" :lg="18" :xl="18"></el-col>
            <el-col :xs="24" :sm="10" :md="8" :lg="6" :xl="6">
              <div class="items-numbers">
                <div class="items-numbers-name">Samlet pris:</div>
                <div class="items-numbers-amount">{{ ConvertToLocalString(totalPrice) }} kr</div>
              </div>
              <div class="items-numbers">
                <div class="items-numbers-name">Moms (25%):</div>
                <div class="items-numbers-amount">{{ ConvertToLocalString(VAT) }} kr</div>
              </div>
              <div class="items-numbers">
                <div class="items-numbers-name">Total:</div>
                <div class="items-numbers-total">{{ ConvertToLocalString(priceWithVAT) }} kr</div>
              </div>
              <div class="items-actions-book">
                <el-checkbox v-model="termsAccepted">
                  Jeg accepterer hermed <a  href="/hjaelp?goTo=Handelsbetingelser" target="_blank">handelsbetingelser</a>
                </el-checkbox>
                <span v-if="showTermsError" class="terms-not-accepted-message"
                  >Handelsbetingelser skal accepteres</span
                >
                <el-button :loading="goToPaymentPressed" v-if="isUserLoggedIn && !payWithInvoicePressed" @click="completeBooking(false)" class="el-button-big el-focus-button"
                  >{{ goToPaymentPressed ? 'Vent venligst ...' : 'Gå til betaling' }}</el-button
                >
                <div style="padding-top: 20px; width: 100%; text-align: center;" v-if="isUserLoggedIn && !goToPaymentPressed">eller</div>
                <el-button :loading="payWithInvoicePressed" v-if="isUserLoggedIn && !goToPaymentPressed" @click="completeBooking(true)" class="el-button-big el-focus-button-inverted" style="margin-left: 0px !important"
                  >{{ payWithInvoicePressed ? 'Vent venligst ...' : 'Betal med faktura' }}</el-button
                >
                <el-button v-if="!isUserLoggedIn" @click="openLoginModal" class="el-button-big el-focus-button"
                  >Login</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div id="onpayForm"></div>
    </el-main>

    <!-- Edit booking dialog -->
    <el-dialog
      title="Book flere uger"
      v-model="editBookingDialogVisible"
      width="370px"
      destroy-on-close
    >
      <div class="edit-booking-description">
        I listen herunder, kan du fra- og tilvælge flere uger:
      </div>
      <el-form :model="editBookingForm">
        <el-form-item>
          <el-select-v2
            v-model="cartItemToEdit.selectedWeeks"
            :options="availableWeeks"
            placeholder="Vælg uge(r)"
            style="width: 100%"
            multiple
          />
        </el-form-item>
        <div class="edit-booking-actions">
          <el-button
            style="float: left"
            @click="editBookingDialogVisible = false"
            >Annuller</el-button
          >
          <el-button style="float: right" type="success" @click="editBooking">Opdater valgte uge(r)</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import Calculation from '../servies/calculation.js';

export default {
  name: "ShoppingCart",
  data() {
    return {
      // Items for the main list
      editBookingDialogVisible: false,
      bookingCompleted: false,
      cartItems: [],
      totalPrice: 0,
      VAT: 0,
      priceWithVAT: 0,
      termsAccepted: false,
      showTermsError: false,
      // Items for the editorial part
      availableWeeks: [],
      selectedWeeksForBooking: [],
      editBookingForm: {
        spotID: 0,
        weekCount: 0,
        weeks: [],
      },
      cartItemToEdit: null,
      goToPaymentPressed: false,
      payWithInvoicePressed: false
    };
  },
  mounted() {
    this.getCart();
  },
  computed: {
      isUserLoggedIn() {
          return this.$store.getters.isLoggedIn;
      }
  },
  methods: {
    ConvertToLocalString(number) {
      return number.toLocaleString();
    },
    getCart() {
      var sessionCart = localStorage.getItem("basket");
      if (sessionCart !== null) {
        this.cartItems = JSON.parse(sessionCart);
        this.calculateHeaderValues();
      }
    },
    getAvailableDate(item) {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}booking/GetAvailableDates?spotID=${item.id}`)
          .then(response => {
              var availableWeeksFromResponse = response.data.data;
              
              this.availableWeeks = [];
              for(var index = 0; index < availableWeeksFromResponse.length; index++)
              {
                  var weekNumber = availableWeeksFromResponse[index].weekNumber;
                  var monday = new Date(availableWeeksFromResponse[index].mondayDate);
                  var sunday = new Date(availableWeeksFromResponse[index].sundayDate);

                  var mondayString = monday.getDate() + '-'+ monday.getMonth() + '/' + monday.getFullYear();
                  var sundayString = sunday.getDate() + '-'+ sunday.getMonth() + '/' + sunday.getFullYear();
                  
                  monday.setHours(2, 0, 0, 0);
                  this.availableWeeks.push({
                      value: monday.toISOString(),
                      label: `Uge ${weekNumber} | ${mondayString} - ${sundayString}`
                  })
              }

              this.cartItemToEdit = item;
              this.editBookingDialogVisible = true;
          });
    },
    openWeekEditorForItem(item) {
      this.getAvailableDate(item);
    },
    editBooking() {
      this.cartItemToEdit.price = Calculation.calculateSpotPricesWithSavingsAndVAT(this.cartItemToEdit.spotSavings, this.cartItemToEdit.selectedWeeks.length, this.cartItemToEdit.pricePrWeek);
      this.$store.commit('overrideItem', this.cartItemToEdit);
      this.calculateHeaderValues();
      this.editBookingDialogVisible = false;
    },
    completeBooking(payWithInvoice) {
      if(this.isUserLoggedIn === false)
      {
          return;
      }
      
      if(this.termsAccepted) {
        if(payWithInvoice === true)
        {
          this.payWithInvoicePressed = true;
        }
        else {
          this.goToPaymentPressed = true;
        }
        this.sendBookingRequest(payWithInvoice);
      }
      else {
          this.showTermsError = true;
      }
    },
    getCartItemsAsBookingRequest(payWithInvoice) {
      var listOfRequestDTO = [];
      for(var index = 0; index < this.cartItems.length; index++)
      {
        var cartItem = this.cartItems[index];
        var startDates = cartItem.selectedWeeks;

        var newRequestDTO = {
            SpotID: cartItem.id,
            BookingDateRanges: [],
            City: cartItem.city,
            region: cartItem.region,
            Address: cartItem.address,
            ImageUrl: cartItem.image,
            SpotTitle: cartItem.title,
            SelectedPriceLevel: cartItem.priceRangeSelectedIndex ?? null
          }

        for(var weekIndex = 0; weekIndex < startDates.length; weekIndex++)
        {
          var monday = startDates[weekIndex];
          var sunday = new Date(monday);
          sunday.setDate(sunday.getDate() + 6);
          sunday.setHours(2);

          newRequestDTO.BookingDateRanges.push({
            StartDate: monday,
            EndDate: sunday,
          })

        }

        listOfRequestDTO.push(newRequestDTO);
      }

      var requestDTO = {
        BookingDetails: listOfRequestDTO,
        PayWithInvoice: payWithInvoice !== null ? payWithInvoice : false
      }

      return requestDTO;
    },
    sendBookingRequest(payWithInvoice) {
      var requestDTO = this.getCartItemsAsBookingRequest(payWithInvoice);
      
      axios.post(`${process.env.VUE_APP_BACKEND_URL}Booking/Create`, requestDTO)
          .then((response) => {
            console.log("response from create: ", response.data.data);
            
            this.$store.commit('emptyCart');
            if(payWithInvoice) {
              window.location.href = response.data.data;
            } else {
              $('#onpayForm').html(response.data.data);
              setTimeout(function() {
              $('#onpayForm [method="post"]').submit();
            }, 500);
            }
          }).catch(error => {
              console.log(error);
          })
    },
    calculateHeaderValues() {
      var that = this;

      this.totalPrice = 0;
      this.VAT = 0;
      this.priceWithVAT = 0;

      if (this.cartItems.length === 0) {
        return;
      }

      for (var i = 0; i < this.cartItems.length; i++) {
        that.totalPrice += this.cartItems[i].price.priceWithoutVAT;
      }
      this.VAT = this.totalPrice * 0.25;
      this.priceWithVAT = this.totalPrice + this.VAT;
    },
    removeItemFromCart(index) {
      this.$store.commit("removeItemFromCart", index);
      if (index > -1) {
        this.cartItems.splice(index, 1);
      }
      this.calculateHeaderValues();
    },
    openLoginModal() {
        $('#login-button').click();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .shopping-cart-page {
    border-top: 1px solid rgb(204 204 204);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .shopping-cart-page {
      margin-top: 50px;
    }
}

.shopping-cart-page {
    width: 100%;
    max-width: 1488px;
    margin: 0 auto;
}

.shopping-cart-contianer {
  width: 100%;
  float: left;
  margin-top: 6rem;
}

.booking-completed-container {
  width: 50%;
  margin: 0 auto;
}

.booking-completed-container div {
  text-align: center;
}

.booking-completed-header {
  color: #9433ff;
  font-weight: 600;
  font-size: 26px;
}

.shopping-cart-title {
  font-size: 24px;
  width: 100%;
  margin: 0 auto;
}

.shopping-cart-item {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-top: 20px;
  max-height: 200px;
  border-bottom: 1px solid rgb(219, 219, 219);
}

.shopping-cart-checkout {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.terms-not-accepted-message {
  font-size: 12px;
  color: red;
}

.shopping-cart-row {
  margin-top: 20px;
}

.item-image {
  max-height: 200px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  float: left;
}

.item-description {
  float: left;
  margin-left: 20px;
}

.item-title {
  font-size: 18px;
  font-weight: 500;
  float: left;
  height: 22px;
  width: 100%;
  margin-top: 0px;
}

.item-region-and-city {
  height: 18px;
  float: left;
  width: 100%;
}

.item-address {
  float: left;
  width: 100%;
}

.item-weekcount {
  float: left;
  width: 100%;
  font-weight: 600;
}

.item-price {
  margin-top: 18%;
  font-size: 18px;
  text-align: right;
}

.item-price span {
  font-weight: 600;
}

.item-actions {
  margin-top: 20%;
}

.item-action-edit, .item-action-remove {
  width: 42px;
}

.item-action-edit {
  background-color: white;
}

.item-action-edit:hover {
  opacity: 0.7;
  border-color: none;
  color: black;
}

.item-action-remove {
  background-color: rgb(255, 80, 80) !important;
  color: white;
}

.item-action-remove:hover {
  opacity: 0.7;
}

.items-numbers {
  float: left;
  width: 100%;
}

.items-numbers-name {
  width: 50%;
  float: left;
  text-align: left;
}

.items-numbers-amount {
  width: 50%;
  float: left;
  text-align: right;
}

.items-numbers-total {
  width: 50%;
  float: left;
  text-align: right;
  font-weight: 600;
  text-decoration: underline;
}

.edit-booking-actions {
  padding-bottom: 30px;
}

.edit-booking-description {
  word-break: break-all;
}

.edit-booking-description span {
  font-weight: 600;
}
</style>
